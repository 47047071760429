import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './css/Index.css'
import Img from '../logo.png' 

const Index = () => {
    const navigate = useNavigate()
    const Login = (e, route) => {
        e.preventDefault()
        let accessToken = window.prompt("Input Access Token: ")
        if (accessToken === "IMPEX") {
            navigate(route)
        }
        else {
            alert("Invalid Access Token")
        }
    }
    return (
        <div className="index">
            <img src={Img} alt="3T Impex" />
            <div className="btns">
                <Link to="/export-readiness" className="btn">Fill Export Readiness Form</Link>
                <Link to="/analyze" className="btn" onClick={(e) => Login(e, '/analyze')}>Start Analyzing</Link>
                <Link to="/records" className="btn" onClick={(e) => Login(e, '/records')}>Access Old Records</Link>
            </div>
        </div>
    )
}

export default Index
