import React, { useEffect, useState } from 'react'
import axios from 'axios';

import './css/Form.css'

import { query, collection, where,getDocs, addDoc, updateDoc } from 'firebase/firestore'
import db from '../firebase'

const sendEmail = async (recipientEmail, name, id) => {
  const apiUrl = 'https://email.3tgtc.com/notify';

  // Create a data object to send in the request
  const data = {
    recipient: recipientEmail,
    name,
    id,
  };

  // Send a POST request using Axios
  axios
    .post(apiUrl, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      console.log('Email sent successfully', response.data);
    })
    .catch((error) => {
      console.error('Error sending email: ', error);
    });
};


const Form = () => {
  const [activeTab, setActiveTab] = useState(0)

  const [answers, setAnswer] = useState({
    fullname: '',
    email: '',
    company: '',
    product: '',
    address: '',
    phoneNumber: '',
    promoters: {
      q0: 0,
      q1: 0,
      q2: 0,
      q3: 0,
      q4: 0
    },
    products: {
      q0: 0,
      q1: 0,
      q2: 0,
      q3: 0,
      q4: 0
    },
    pricing: {
      q0: 0,
      q1: 0,
      q2: 0,
      q3: 0,
      q4: 0
    },
    predisposition: {
      q0: 0,
      q1: 0,
      q2: 0,
      q3: 0,
      q4: 0
    },
    payment: {
      q0: 0,
      q1: 0,
      q2: 0,
      q3: 0,
      q4: 0
    },
    purpose: {
      q0: 0,
      q1: 0,
      q2: 0,
      q3: 0,
      q4: 0
    },
    production: {
      q0: 0,
      q1: 0,
      q2: 0,
      q3: 0,
      q4: 0
    },
    positioning: {
      q0: 0,
      q1: 0,
      q2: 0,
      q3: 0,
      q4: 0
    },
    proficiency: {
      q0: 0,
      q1: 0,
      q2: 0,
      q3: 0,
      q4: 0
    },
    people: {
      q0: 0,
      q1: 0,
      q2: 0,
      q3: 0,
      q4: 0
    },
    paperwork: {
      q0: 0,
      q1: 0,
      q2: 0,
      q3: 0,
      q4: 0
    },
    potentials: {
      q0: 0,
      q1: 0,
      q2: 0,
      q3: 0,
      q4: 0
    },
    promotion: {
      q0: 0,
      q1: 0,
      q2: 0,
      q3: 0,
      q4: 0
    },
    purchasers: {
      q0: 0,
      q1: 0,
      q2: 0,
      q3: 0,
      q4: 0
    },
    partnership: {
      q0: 0,
      q1: 0,
      q2: 0,
      q3: 0,
      q4: 0
    }
  })
  const [ansCount, setAnsCount] = useState(0)

  const [msg, setMsg] = useState({
    type: '',
    message: ''
  })

  useEffect(() => {
    let newAnsCount = 0
    for(const category in answers) {
      if (answers[category].hasOwnProperty('q0')) {
        for (let i = 0; i <=4; i++) {
          const qkey = 'q' + i;

          if (answers[category][qkey] !== 0 ) {
            newAnsCount++
          }
        }
      } else if(answers[category] !== '') {
          newAnsCount++
      }
    }
    setAnsCount(newAnsCount)
  }, [answers])

  useEffect(() => {
    // Check if there is a message to clear
    if (msg.type || msg.message) {
      // Schedule a function to reset the message after 3 seconds
      const timer = setTimeout(() => {
        setMsg({
          type: '',
          message: ''
        });
      }, 3000);

      // Clear the timer if the component unmounts or if the message changes
      return () => {
        clearTimeout(timer);
      };
    }
  }, [msg])

  const updateInfo = (e, field) => {
    let copyAnswer = {...answers}
    copyAnswer[field] = e.target.value
    setAnsCount(ansCount => copyAnswer[field] !== '' ? ansCount + 1: ansCount - 1)
    setAnswer(copyAnswer)
  }

  const updateOption = (e, field, option) => {
    let copyAnswer = {...answers}
    copyAnswer[field][option] = parseInt(e.target.value)
    setAnsCount(ansCount => copyAnswer[field][option] !== 0 ? ansCount + 1 : ansCount - 1)
    setAnswer(copyAnswer)
  }


  const submitForm = (e) => {
    e.preventDefault()

    let analyzedData = {...answers}
    
    let score = 0
    if (ansCount < 81) {
      setMsg({type: 'error', message: "Incomplete form, please fill all required fields"});
    } else {
      const averageObject = {};
      for (const category in answers) {
        if (answers[category].hasOwnProperty('q0')) {
          // Initialize variables to calculate the sum and count
          let sum = 0;
          let count = 0;
      
          // Iterate through q0, q1, q2, q3, q4
          for (let i = 0; i <= 4; i++) {
            const key = 'q' + i;
            if (answers[category].hasOwnProperty(key)) {
              sum += answers[category][key];
              count++;
            }
          }
      
          // Calculate the average and set it in the new object
          let avg = count > 0 ? Math.round(sum / count) : 0;
          averageObject[category] = avg.toFixed(1)
          score = score + avg
        }
      }
      analyzedData = {...analyzedData, ...averageObject};
      let percent = ((score / 75) * 100).toFixed(1)
      let category = percent > 90? "A": percent > 70? "B" : percent > 50? "C" : percent > 30? "D" : "E"  
      let newProfile = {timestamp: new Date(), ...analyzedData, score: score.toFixed(1), percent, category}


      const q = query(collection(db, "records"), where("email", "==", newProfile.email))
      getDocs(q).then(docs => {
          if (docs.empty && ansCount > 80) {
            addDoc(collection(db, 'records'), newProfile).then((doc) => {
              sendEmail(newProfile.email, newProfile.fullname, doc.id).then(() => {
                setAnswer({
                  fullname: '',
                  email: '',
                  company: '',
                  product: '',
                  address: '',
                  phoneNumber: '',
                  promoters: {
                    q0: 0,
                    q1: 0,
                    q2: 0,
                    q3: 0,
                    q4: 0
                  },
                  products: {
                    q0: 0,
                    q1: 0,
                    q2: 0,
                    q3: 0,
                    q4: 0
                  },
                  pricing: {
                    q0: 0,
                    q1: 0,
                    q2: 0,
                    q3: 0,
                    q4: 0
                  },
                  predisposition: {
                    q0: 0,
                    q1: 0,
                    q2: 0,
                    q3: 0,
                    q4: 0
                  },
                  payment: {
                    q0: 0,
                    q1: 0,
                    q2: 0,
                    q3: 0,
                    q4: 0
                  },
                  purpose: {
                    q0: 0,
                    q1: 0,
                    q2: 0,
                    q3: 0,
                    q4: 0
                  },
                  production: {
                    q0: 0,
                    q1: 0,
                    q2: 0,
                    q3: 0,
                    q4: 0
                  },
                  positioning: {
                    q0: 0,
                    q1: 0,
                    q2: 0,
                    q3: 0,
                    q4: 0
                  },
                  proficiency: {
                    q0: 0,
                    q1: 0,
                    q2: 0,
                    q3: 0,
                    q4: 0
                  },
                  people: {
                    q0: 0,
                    q1: 0,
                    q2: 0,
                    q3: 0,
                    q4: 0
                  },
                  paperwork: {
                    q0: 0,
                    q1: 0,
                    q2: 0,
                    q3: 0,
                    q4: 0
                  },
                  potentials: {
                    q0: 0,
                    q1: 0,
                    q2: 0,
                    q3: 0,
                    q4: 0
                  },
                  promotion: {
                    q0: 0,
                    q1: 0,
                    q2: 0,
                    q3: 0,
                    q4: 0
                  },
                  purchasers: {
                    q0: 0,
                    q1: 0,
                    q2: 0,
                    q3: 0,
                    q4: 0
                  },
                  partnership: {
                    q0: 0,
                    q1: 0,
                    q2: 0,
                    q3: 0,
                    q4: 0
                  }
                })
                setActiveTab(0)
                setAnsCount(0)
  
                setMsg({type: 'success', message: "Survey submitted successfully"});
  
                setTimeout(() => {
                  window.location.replace('/user-report/' + doc.id)
                }, 3000)
              })
            })
          } else if (!docs.empty && ansCount > 80) {
            const docRef = docs.docs[0].ref;
            const docid = docs.docs[0].id;
            updateDoc(docRef, newProfile).then(() => {
              sendEmail(newProfile.email, newProfile.fullname, docid).then(() => {
                setAnswer({
                  fullname: '',
                  email: '',
                  company: '',
                  product: '',
                  address: '',
                  phoneNumber: '',
                  promoters: {
                    q0: 0,
                    q1: 0,
                    q2: 0,
                    q3: 0,
                    q4: 0
                  },
                  products: {
                    q0: 0,
                    q1: 0,
                    q2: 0,
                    q3: 0,
                    q4: 0
                  },
                  pricing: {
                    q0: 0,
                    q1: 0,
                    q2: 0,
                    q3: 0,
                    q4: 0
                  },
                  predisposition: {
                    q0: 0,
                    q1: 0,
                    q2: 0,
                    q3: 0,
                    q4: 0
                  },
                  payment: {
                    q0: 0,
                    q1: 0,
                    q2: 0,
                    q3: 0,
                    q4: 0
                  },
                  purpose: {
                    q0: 0,
                    q1: 0,
                    q2: 0,
                    q3: 0,
                    q4: 0
                  },
                  production: {
                    q0: 0,
                    q1: 0,
                    q2: 0,
                    q3: 0,
                    q4: 0
                  },
                  positioning: {
                    q0: 0,
                    q1: 0,
                    q2: 0,
                    q3: 0,
                    q4: 0
                  },
                  proficiency: {
                    q0: 0,
                    q1: 0,
                    q2: 0,
                    q3: 0,
                    q4: 0
                  },
                  people: {
                    q0: 0,
                    q1: 0,
                    q2: 0,
                    q3: 0,
                    q4: 0
                  },
                  paperwork: {
                    q0: 0,
                    q1: 0,
                    q2: 0,
                    q3: 0,
                    q4: 0
                  },
                  potentials: {
                    q0: 0,
                    q1: 0,
                    q2: 0,
                    q3: 0,
                    q4: 0
                  },
                  promotion: {
                    q0: 0,
                    q1: 0,
                    q2: 0,
                    q3: 0,
                    q4: 0
                  },
                  purchasers: {
                    q0: 0,
                    q1: 0,
                    q2: 0,
                    q3: 0,
                    q4: 0
                  },
                  partnership: {
                    q0: 0,
                    q1: 0,
                    q2: 0,
                    q3: 0,
                    q4: 0
                  }
                })
                setActiveTab(0)
                setAnsCount(0)
  
                setMsg({type: 'success', message: "Survey submitted successfully"});
  
                setTimeout(() => {
                  window.location.replace('/user-report/' + docid)
                }, 3000)
              })
            })
          }
      })
    }
  }

  return (
    <div className="form">
      <div className="header">
        <h1 className='text'>Export Readiness</h1>
      </div>
      <div className="main">
        {
          msg.message &&
          <p className={msg.type === 'error'? 'error_msg':'success_msg'}> <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 14 14"><g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"><circle cx="7" cy="7" r="6.5"></circle><path d="M7 3.5v3"></path><circle cx="7" cy="9.5" r=".5"></circle></g></svg> {msg.message}</p>
        }

        <div className="tabs">
          {formTabs.map(tab => (
            <button key={tab.title} disabled={activeTab === tab.id} onClick={()=>setActiveTab(tab.id)} className={activeTab === tab.id? 'active': ''}>{tab.title}</button>
            ))}
        </div>

        <p>Fields marked with an <span className='asterix'>*</span> are required</p>

        {formTabs.map(tab => { 
          if(tab.id === activeTab) {
            return <div key={tab.id}>
              {tab.fields.map((field, i) => (
                <div key={field.label} className='formitem'>
                  <label className='label'>{field.label}</label>
                  {(field.type !== "radio") ? (
                    <input type={field.type} className='textbox' defaultValue={answers[field.id]} onChange={(e) => updateInfo(e, field.id)} />
                  ):(  
                    <div className="options">
                      <div className="option">
                        <input type={field.type} name={field.category + i} id={field.category + i + 'vh'} value={5} checked={answers[field.category]['q'+i] === 5} onChange={(e) => updateOption(e, field.category, 'q'+i)} /> <label htmlFor={field.category + i + 'vh'}>Very High</label>
                      </div>

                      <div className="option">
                        <input type={field.type} name={field.category + i} id={field.category + i + 'h'} value={4} checked={answers[field.category]['q'+i] === 4} onChange={(e) => updateOption(e, field.category, 'q'+i)} /> <label htmlFor={field.category + i + 'h'}>High</label>
                      </div>

                      <div className="option">
                        <input type={field.type} name={field.category + i} id={field.category + i + 'i'} value={3} checked={answers[field.category]['q'+i] === 3} onChange={(e) => updateOption(e, field.category, 'q'+i)} /> <label htmlFor={field.category + i + 'i'}>Indifferent</label>
                      </div>

                      <div className="option">
                        <input type={field.type} name={field.category + i} id={field.category + i + 'l'} value={2} checked={answers[field.category]['q'+i] === 2} onChange={(e) => updateOption(e, field.category, 'q'+i)} /> <label htmlFor={field.category + i + 'l'}>Low</label>
                      </div>

                      <div className="option">
                        <input type={field.type} name={field.category + i} id={field.category + i + 'vl'} value={1} checked={answers[field.category]['q'+i] === 1} onChange={(e) => updateOption(e, field.category, 'q'+i)} /> <label htmlFor={field.category + i + 'vl'}>Very Low</label>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          }
          return null
        })}


        {
          msg.message &&
          <p className={msg.type === 'error'? 'error_msg':'success_msg'}> <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 14 14"><g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"><circle cx="7" cy="7" r="6.5"></circle><path d="M7 3.5v3"></path><circle cx="7" cy="9.5" r=".5"></circle></g></svg> {msg.message}</p>
        }
        <div className="nav">
          {activeTab > 0 ? 
            <button onClick={()=>setActiveTab((activeTab)=> activeTab - 1)}>Previous</button>
            :
            <span></span>
          }
          {activeTab < formTabs.length - 1 ?
            <button onClick={()=>setActiveTab((activeTab)=> activeTab + 1)}>Next</button>
            :
            <button onClick={(e) => submitForm(e)} disabled={msg.message} >Submit</button>
          }
        </div>

      </div>
    </div>
  )
}

export default Form

export const formTabs = [
  {
    id: 0,
    title: "Your Information",
    fields: [
      {
        id: "fullname",
        label: "Full Name Representative",
        type: "text",
        priority: "*"
      },
      {
        id: "email",
        label: "Email Address",
        type: "email",
        priority: "*"
      },
      {
        id: "company",
        label: "Company Name",
        type: "text",
        priority: "*"
      },
      {
        id: "product",
        label: "Product Name",
        type: "text",
        priority: "*"
      },
      {
        id: "address",
        label: "Company Address",
        type: "text",
        priority: "*"
      },
      {
        id: "phoneNumber",
        label: "Phone Number",
        type: "text",
        priority: "*"
      },
    ]
  },
  {
    id: 1,
    title: "Promoters", 
    fields: [
      {
        category: "promoters",
        label: "How will you describe the commitment of your management to exportation?",
        type: "radio",
        priority: "*"
      },
      {
        category: "promoters",
        label: "How will you describe the management desire to sell in foreign markets?",
        type: "radio",
        priority: "*"
      },
      {
        category: "promoters",
        label: "How experienced and familiar is the management in international business?",
        type: "radio",
        priority: "*"
      },
      {
        category: "promoters",
        label: "How will you describe the skills of the business owner to manage export transactions?",
        type: "radio",
        priority: "*"
      },
      {
        category: "promoters",
        label: "How will you describe the disposition of the management to fund export transactions?",
        type: "radio",
        priority: "*"
      },
    ]
  },
  {
    id: 2,
    title: "Product(s)", 
    fields: [
      {
        category: "products",
        label: "How will you describe your familiarity with the clear advantages that your product has over competing products?",
        type: "radio",
        priority: "*"
      },
      {
        category: "products",
        label: "How will you describe your satisfaction about the resilience of your product to withstand harsh or widely varying environmental condition in the export market?",
        type: "radio",
        priority: "*"
      },
      {
        category: "products",
        label: "How will you describe the ease with which anyone to use your company's product in the export market without any technical support?",
        type: "radio",
        priority: "*"
      },
      {
        category: "products",
        label: "How will you describe the ease of adapting your product to make it suitable the export market?",
        type: "radio",
        priority: "*"
      },
      {
        category: "products",
        label: "How will you describe the resilient quality of your product packaging to withstand the stress of international freight to the export market?",
        type: "radio",
        priority: "*"
      },
    ]
  },
  {
    id: 3,
    title: "Pricing", 
    fields: [
      {
        category: "pricing",
        label: "How competitive is the price of your products in the local market?",
        type: "radio",
        priority: "*"
      },
      {
        category: "pricing",
        label: "How satisfied are you on the impact of economy of scale on cost reduction?",
        type: "radio",
        priority: "*"
      },
      {
        category: "pricing",
        label: "How will you describe your company’s access to information on the cost elements in exportation?",
        type: "radio",
        priority: "*"
      },
      {
        category: "pricing",
        label: "How will you describe your company’s access to information about competitor’s prices in export market?",
        type: "radio",
        priority: "*"
      },
      {
        category: "pricing",
        label: "How will you describe your understanding of strategies for cost reduction in export business?",
        type: "radio",
        priority: "*"
      },
    ]
  },
  {
    id: 4,
    title: "Predisposition", 
    fields: [
      {
        category: "predisposition",
        label: "How will you describe the exposure and familiarity of the staff of your company to exportation?",
        type: "radio",
        priority: "*"
      },
      {
        category: "predisposition",
        label: "How will you describe the number of staff of the company that have worked abroad?",
        type: "radio",
        priority: "*"
      },
      {
        category: "predisposition",
        label: "How will you describe the number of staff of the company that have schooled abroad?",
        type: "radio",
        priority: "*"
      },
      {
        category: "predisposition",
        label: "How will you describe the number of staff of the company that have travelled abroad for holidays?",
        type: "radio",
        priority: "*"
      },
      {
        category: "predisposition",
        label: "How will you describe the number of staff of the company that can speak an international foreign language?",
        type: "radio",
        priority: "*"
      },
    ]
  },
  {
    id: 5,
    title: "Purpose", 
    fields: [
      {
        category: "purpose",
        label: "How will you describe the desire of the business to build a global brand?",
        type: "radio",
        priority: "*"
      },
      {
        category: "purpose",
        label: "How will you describe the quality of the strategies of the business to access government incentives?",
        type: "radio",
        priority: "*"
      },
      {
        category: "purpose",
        label: "How will you describe the quality of plans of the business to take advantage of free trade agreement like AfCFTA?",
        type: "radio",
        priority: "*"
      },
      {
        category: "purpose",
        label: "How will you describe the quality of the plans of the business in using export as a survival strategy?",
        type: "radio",
        priority: "*"
      },
      {
        category: "purpose",
        label: "How will you describe the desire of the business to earn in foreign currency?",
        type: "radio",
        priority: "*"
      },
    ]
  },
  {
    id: 6,
    title: "Payment", 
    fields: [
      {
        category: "payment",
        label: "How will you appraise the financial capacity of the business to fund new export demands?",
        type: "radio",
        priority: "*"
      },
      {
        category: "payment",
        label: "How will you appraise the ability of the business to secure export finance facility from banks?",
        type: "radio",
        priority: "*"
      },
      {
        category: "payment",
        label: "How will you appraise the ability of the business to handle trade finance instruments?",
        type: "radio",
        priority: "*"
      },
      {
        category: "payment",
        label: "How will you appraise the ability of the business to negotiate for a secured payment method like letter of credit?",
        type: "radio",
        priority: "*"
      },
      {
        category: "payment",
        label: "How will you appraise the quality of asset of the business to raise funding?",
        type: "radio",
        priority: "*"
      },
    ]
  },
  {
    id: 7,
    title: "Production", 
    fields: [
      {
        category: "production",
        label: "How will you describe the capacity of the factory to handle 4 demand in export market?",
        type: "radio",
        priority: "*"
      },
      {
        category: "production",
        label: "How will you describe the logistics in place to move large volume of goods to export market?",
        type: "radio",
        priority: "*"
      },
      {
        category: "production",
        label: "How will you describe the excess capacity of the production factory?",
        type: "radio",
        priority: "*"
      },
      {
        category: "production",
        label: "How will you describe the ease of access to inventory to fulfil a surge in demand?",
        type: "radio",
        priority: "*"
      },
      {
        category: "production",
        label: "How will you describe the likelihood of the business to get partners for contract manufacturing?",
        type: "radio",
        priority: "*"
      },
    ]
  },
  {
    id: 8,
    title: "Positioning", 
    fields: [
      {
        category: "positioning",
        label: "How will you evaluate the goodwill (reputation) possessed by the business both locally and abroad?",
        type: "radio",
        priority: "*"
      },
      {
        category: "positioning",
        label: "How will you evaluate the share of the business in the domestic market?",
        type: "radio",
        priority: "*"
      },
      {
        category: "positioning",
        label: "How will you evaluate the level of unsolicited export orders receive by the business?",
        type: "radio",
        priority: "*"
      },
      {
        category: "positioning",
        label: "How will you evaluate the level of foreign affiliates in the network of your firm’s local partners?",
        type: "radio",
        priority: "*"
      },
      {
        category: "positioning",
        label: "How will you evaluate the loyalty of your customer to your business offering in the local market?",
        type: "radio",
        priority: "*"
      },
    ]
  },
  {
    id: 9,
    title: "Proficiency", 
    fields: [
      {
        category: "proficiency",
        label: "How will you describe the level of skill of the staff of your company in international marketing?",
        type: "radio",
        priority: "*"
      },
      {
        category: "proficiency",
        label: "How will you describe the level of expertise of the company’s staff in mitigating export risk?",
        type: "radio",
        priority: "*"
      },
      {
        category: "proficiency",
        label: "How will you describe the level of cultural intelligence possessed by the company’s staff?",
        type: "radio",
        priority: "*"
      },
      {
        category: "proficiency",
        label: "How will you describe the level of understanding of payment methods (like letter of credit and bill for collection) by the company’s staff?",
        type: "radio",
        priority: "*"
      },
      {
        category: "proficiency",
        label: "How will you describe in general the level of competence of company’s personnel in export business management?",
        type: "radio",
        priority: "*"
      },
    ]
  },
  {
    id: 10,
    title: "People", 
    fields: [
      {
        category: "people",
        label: "How will you evaluate your company's understanding of the demography of the consumer of its products in the targeted export market?",
        type: "radio",
        priority: "*"
      },
      {
        category: "people",
        label: "How will evaluate your company's understanding of why consumers currently buy competing products in the targeted export market?",
        type: "radio",
        priority: "*"
      },
      {
        category: "people",
        label: "How will you evaluate your company's understanding of the purchasing power of the consumer in the targeted export market?",
        type: "radio",
        priority: "*"
      },
      {
        category: "people",
        label: "How will you evaluate your company's understanding of the packaging requirements of consumers in the targeted export market?",
        type: "radio",
        priority: "*"
      },
      {
        category: "people",
        label: "How will you evaluate your company's understanding of the labelling requirements of the consumers in the targeted export market?",
        type: "radio",
        priority: "*"
      },
    ]
  },
  {
    id: 11,
    title: "Paperwork", 
    fields: [
      {
        category: "paperwork",
        label: "How will you appraise your company’s understanding of product quality documentation in the targeted export market?",
        type: "radio",
        priority: "*"
      },
      {
        category: "paperwork",
        label: "How will you appraise your company’s understanding of the documentation requirements under a free trade agreement like AfCFTA",
        type: "radio",
        priority: "*"
      },
      {
        category: "paperwork",
        label: "How will you appraise your company’s understanding of pre & post import documentation in the targeted export market?",
        type: "radio",
        priority: "*"
      },
      {
        category: "paperwork",
        label: "How will you appraise your company’s understanding of home country’s pre and post-export documentation requirements?",
        type: "radio",
        priority: "*"
      },
      {
        category: "paperwork",
        label: "How will you appraise your company’s understanding of what constitutes document discrepancies in export trade?",
        type: "radio",
        priority: "*"
      },
    ]
  },
  {
    id: 12,
    title: "Potentials", 
    fields: [
      {
        category: "potentials",
        label: "How will you evaluate your company’s understanding of the African markets size for your products?",
        type: "radio",
        priority: "*"
      },
      {
        category: "potentials",
        label: "How will you evaluate your company’s understanding of the global markets size for your products?",
        type: "radio",
        priority: "*"
      },
      {
        category: "potentials",
        label: "How will you evaluate your company’s understanding of the global trend of demand for your products?",
        type: "radio",
        priority: "*"
      },
      {
        category: "potentials",
        label: "How will you evaluate your company’s understanding of the drivers of demand for your products in the export markets?",
        type: "radio",
        priority: "*"
      },
      {
        category: "potentials",
        label: "How will you evaluate your company’s understanding of the emerging markets for your products?",
        type: "radio",
        priority: "*"
      },
    ]
  },
  {
    id: 13,
    title: "Promotion", 
    fields: [
      {
        category: "promotion",
        label: "How will you appraise your understanding of how to reach your buyers in the targeted export market?",
        type: "radio",
        priority: "*"
      },
      {
        category: "promotion",
        label: "How will you evaluate your ability to obtain export market intelligence report for your products?",
        type: "radio",
        priority: "*"
      },
      {
        category: "promotion",
        label: "How will you appraise your understanding of the usage of global online trade portals?",
        type: "radio",
        priority: "*"
      },
      {
        category: "promotion",
        label: "How will you appraise your understanding of international promotion on social media platforms?",
        type: "radio",
        priority: "*"
      },
      {
        category: "promotion",
        label: "How will you evaluate your understanding of the components of international marketing?",
        type: "radio",
        priority: "*"
      },
    ]
  },
  {
    id: 14,
    title: "Purchasers", 
    fields: [
      {
        category: "purchasers",
        label: "How will appraise your level of awareness and understanding of the different export market entry strategies?",
        type: "radio",
        priority: "*"
      },
      {
        category: "purchasers",
        label: "How will you appraise your understanding of the right strategy for your target export market?",
        type: "radio",
        priority: "*"
      },
      {
        category: "purchasers",
        label: "How will you appraise your ability to setup distribution channels in the export market?",
        type: "radio",
        priority: "*"
      },
      {
        category: "purchasers",
        label: "How will you evaluate your understanding of market structure in the targeted export market?",
        type: "radio",
        priority: "*"
      },
      {
        category: "purchasers",
        label: "How will you evaluate your understanding of the challenge of distribution in the export market?",
        type: "radio",
        priority: "*"
      },
    ]
  },
  {
    id: 15,
    title: "Partnership", 
    fields: [
      {
        category: "partnership",
        label: "How will you rate your business network in markets abroad?",
        type: "radio",
        priority: "*"
      },
      {
        category: "partnership",
        label: "How will you rate your capacity to get an agent in the export market?",
        type: "radio",
        priority: "*"
      },
      {
        category: "partnership",
        label: "How will you rate your capacity to get a distributor in the export market?",
        type: "radio",
        priority: "*"
      },
      {
        category: "partnership",
        label: "How will you rate ability to source for business partners in the export market?",
        type: "radio",
        priority: "*"
      },
      {
        category: "partnership",
        label: "How will you rate your ability to get service providers in the export market?",
        type: "radio",
        priority: "*"
      },
    ]
  }
]