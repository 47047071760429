import React, { useEffect, useState } from 'react'
import {Link} from 'react-router-dom'
import './css/Analyze.css'
import Papa from 'papaparse'
import { IconLoading, IconOpenInNew } from './Icons/Icons'
import { query, collection, where,getDocs, addDoc } from 'firebase/firestore'
import db from '../firebase'


const Analyze = () => {
    const [csvFile, setCsvFile] = useState()
    const [cleanReports, setCleanReports] = useState()
    const [profiles, setProfiles] = useState()
    const [loading, setLoading] = useState(false)
    const [done, setDone] = useState(false)

    const ProcessFile = (e) => {
        e.preventDefault()
        setLoading(true)
        const csvResult = new FileReader()
        csvResult.onload = (e) => {
            const csvData = e.target.result
            return new Promise(() => {
                let reports = []
                Papa.parse(csvData, {
                    delimiter: "\\",	// auto-detect
                    newline: "",
                    fastMode: true,
                    complete: results => {
                        reports = results.data
                    }
                });
                if (reports) {
                    let newReport = []
                    reports.shift()
                    reports.forEach(report => {
                        let tempReport = report[0].split("\"")
                        let cleanReports = tempReport[0].split(",")
                        cleanReports.push(tempReport[1])
                        if (tempReport.length > 1) {
                            tempReport[tempReport.length - 1].replace(",0 / 400", "")
                            if (tempReport[tempReport.length - 1][0] === ",") {
                                tempReport[tempReport.length - 1][0].substring(1)
                            }
                            let numPos = tempReport[tempReport.length - 1].search(/(\+|08|09|07|8|7|9|2)/g)
                            cleanReports.push(tempReport[tempReport.length - 1].substr(0, numPos))
                            cleanReports.push(...tempReport[tempReport.length - 1].slice(numPos).split(","))
                        }
                        cleanReports = cleanReports.filter(item => item !== "0 / 400" && item !== undefined)
                        
                        cleanReports = cleanReports.filter(report => report !== "," && report !== "" && report !== " ")
                        let freshReport = cleanReports.slice(0, 4)
                        freshReport.push(...cleanReports.splice(cleanReports.length - 76).map(item => {
                            switch (item.trim()) {
                                case "Very High":
                                    return 5
                                case "High":
                                    return 4
                                case "Indifferent":
                                    return 3
                                case "Low":
                                    return 2
                                case "Very Low":
                                    return 1
                                default:
                                    return item
                            }
                        }))
                        newReport.push(freshReport)
                    })
                    setCleanReports(newReport)
                }
            });
        }
        csvResult.readAsText(csvFile) 
    }
    useEffect(() => {
        if (cleanReports) {
            let newProfiles = []
            cleanReports.forEach(report => {
                let newAnalysis = [...report.splice(0, 5)]
                let score = 0
                for (let i = 0; i < 15; i += 1) {
                    let list = report.splice(0, 5)
                    let sum = 0
                    list.map(item => sum += parseInt(item))
                    let avg = sum / 5
                    score += avg
                    newAnalysis.push(avg)              
                }
                let percent = (score / 75) * 100
                let category = percent > 90? "A": percent > 70 ? "B" : percent > 50? "C" : percent > 30? "D" : "E"  
                let thisProfile = { timestamp: new Date(newAnalysis[0]), email: newAnalysis[1], company: newAnalysis[2], address: newAnalysis[3], phoneNumber: newAnalysis[4], promoters: parseInt(newAnalysis[5]).toFixed(1), products: parseInt(newAnalysis[6]).toFixed(1), pricing: parseInt(newAnalysis[7]).toFixed(1), predisposition: parseInt(newAnalysis[8]).toFixed(1), purpose: parseInt(newAnalysis[9]).toFixed(1), payment: parseInt(newAnalysis[10]).toFixed(1), production: parseInt(newAnalysis[11]).toFixed(1), positioning: parseInt(newAnalysis[12]).toFixed(1), proficiency: parseInt(newAnalysis[13]).toFixed(1), people: parseInt(newAnalysis[14]).toFixed(1), paperwork: parseInt(newAnalysis[15]).toFixed(1), potentials: parseInt(newAnalysis[16]).toFixed(1), promotion: parseInt(newAnalysis[17]).toFixed(1), purchasers: parseInt(newAnalysis[18]).toFixed(1), partnership: parseInt(newAnalysis[19]).toFixed(1), score: score.toFixed(1), percent: percent.toFixed(1), category: category }
                if (thisProfile.email && thisProfile.email.includes('@')) {
                // if (thisProfile.email && thisProfile.email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/)) {
                    // ===========================================================================
                    // Add record to database
                    const q = query(collection(db, "records"), where("email", "==", thisProfile.email))
                    getDocs(q).then(docs => {
                        if (docs.empty) {
                            newProfiles.push(thisProfile)
                            addDoc(collection(db, 'records'), thisProfile)
                        }
                    })
                    // ===========================================================================
                }
            })
            setProfiles(newProfiles)
            setLoading(false)
            setDone(true)
        }
    }, [cleanReports])
    return (
        <div className="analyze">
            <div className="btns">
                <Link to="/" className="btn">Home</Link>
                <Link to="/records" className="btn">Access Old Records</Link>
            </div>
            <br />
            <br />
            <h1>Upload CSV here:</h1>
            <form onSubmit={ProcessFile} className="form_uploader" encType="multipart/form-data" >
                <input type="file" accept=".csv" required onChange={(e) => setCsvFile(e.target.files[0])} />
                {loading ? <span className="spinner"><IconLoading /></span>: done ? <span style={{ fontSize: "2rem", fontWeight: "bold" }}>. . . Analyzing Completed</span>  : <button>Analyze</button>}
            </form>

            {profiles?.length > 0 &&
                <div className="table_responsive">
                    <table className="table">
                    <thead>
                        <tr>
                            <th>ACTION</th>
                            <th>TIMESTAMP</th>
                            <th> EMAIL ADDRESS</th>
                            <th> COMPANY NAME & REPRESENTATIVE NAME</th>
                            <th> COMPANY ADDRESS</th>
                            <th> PHONE NUMBER</th>
                            <th> PROMOTERS</th>
                            <th> PRODUCTS</th>
                            <th> PRICING</th>
                            <th> PREDISPOSITION</th>
                            <th> PURPOSE</th>
                            <th> PAYMENT</th>
                            <th> PRODUCTION</th>
                            <th> POSITIONING</th>
                            <th> PROFICIENCY</th>
                            <th> PEOPLE</th>
                            <th> PAPERWORK</th>
                            <th> POTENTIALS</th>
                            <th> PROMOTION</th>
                            <th> PURCHASERS</th>
                            <th> PARTNERSHIP</th>
                            <th> SCORE</th>
                            <th> PERCENTAGE</th>
                            <th> CATEGORY</th>
                        </tr>
                    </thead>
                    <tbody>
                        {profiles.map(profile => (
                            <tr key={profile.timestamp}>
                                <td><Link to={`/report/${profile.email }`} className="linkIcon"><IconOpenInNew /></Link> </td>
                                <td>{profile.timestamp }</td>
                                <td>{profile.email }</td>
                                <td>{profile.company }</td>
                                <td>{profile.address }</td>
                                <td>{profile.phoneNumber }</td>
                                <td>{profile.promoters }</td>
                                <td>{profile.products }</td>
                                <td>{profile.pricing }</td>
                                <td>{profile.predisposition }</td>
                                <td>{profile.purpose }</td>
                                <td>{profile.payment }</td>
                                <td>{profile.production }</td>
                                <td>{profile.positioning }</td>
                                <td>{profile.proficiency }</td>
                                <td>{profile.people }</td>
                                <td>{profile.paperwork }</td>
                                <td>{profile.potentials }</td>
                                <td>{profile.promotion }</td>
                                <td>{profile.purchasers }</td>
                                <td>{profile.partnership }</td>
                                <td>{profile.score }</td>
                                <td>{profile.percent }</td>
                                <td>{profile.category }</td>
                            </tr>
                        ))}
                    </tbody>
                    </table>
                </div>
            }
        </div>
    )
}

export default Analyze
