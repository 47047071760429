import React from 'react'
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Analyze from './pages/Analyze'
import Index from './pages/Index'
import Records from './pages/Records'
import Report from './pages/Report'
import UserReport from './pages/UserReport'
import Form from './pages/Form'

const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Index />} />
        <Route path="/analyze" element={<Analyze />} />
        <Route path="/records" element={<Records />} />
        <Route path="/export-readiness" element={<Form />} />
        <Route path="/report/:id" element={<Report />} />
        <Route path="/user-report/:id" element={<UserReport />} />
      </Routes>
    </Router>
  )
}

export default App
