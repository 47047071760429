import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { IconLoading} from './Icons/Icons'
import "./css/Report.css"
import { getDoc, doc } from 'firebase/firestore'
import db from '../firebase'
import Img from '../logo.png'

const Report = () => {
    const { id } = useParams()
    const target = useRef()
    const [report, setReport] = useState()
    const [loading, setLoading] = useState(true)
    const [exported, setExported] = useState(false)

    useEffect(() => {
        getDoc(doc(db, "records", id.trim())).then(res => {
            setReport(res.data())
            setLoading(false)
        })
    }, [id])
    
    const Export = () => {
        const divContents = target.current.innerHTML
        const a = window.open('', '', 'height=500, width=500');
        a.document.write('<html>');
        a.document.write('<head><title>')
        a.document.write(`${report?.email.trim() + report?.timestamp.seconds}`);
        a.document.write('</title></head>');
        a.document.write('<body >');
        a.document.write(divContents);
        a.document.write('</body></html>');
        a.document.close();
        a.print();
        setExported(true)
    }

    return (
        <div className="report">
            <div style={{display: "flex", justifyContent: 'space-around'}}>
                <Link to="/export-readiness" className="btn">Fill Export Readiness Form</Link>
                {!loading && !exported && <button onClick={Export} className="btn" style={{ border: "3px solid #40e48a", backgroundColor: "#10804c" }}>Export</button>}
            </div>
            {!loading ?
                <div className="main" ref={target} style={{padding: "50px 20px", margin: "30px 0px", backgroundColor: "#f1f1f1", borderRadius: "10px"}}>
                    <div className="profile" style={{display: "flex", justifyContent: "space-between"}}>
                        <img src={Img} alt="3T Impex" style={{width: "150px", margin: "0px 20px", objectFit: "contain", borderRadius: "50%"}} />
                        <div className="user">
                            <h2>{report?.email}</h2>
                            <h4 style={{margin: "6px 0px", color: "#858484"}}>{report?.product}</h4>
                            <h4 style={{margin: "6px 0px", color: "#858484"}}>{report?.company}</h4>
                            <p style={{margin: "6px 0px", color: "#858484"}}>{report?.address}</p>
                            <p style={{margin: "6px 0px", color: "#858484"}}>{report?.phoneNumber}</p>
                        </div>
                    </div>
                    <div className="report_table" style={{width: "100%", margin: "50px 0px"}}>
                        <table style={{width: "100%", textAlign: "center", borderCollapse: "collapse", fontSize: "13px"}}>
                            <thead style={{background: "#ffffff"}}>
                                <tr>
                                    <th style={{whiteSpace: "nowrap", padding: "20px", border: "1px solid #cccccc6e"}} colSpan="2"></th>
                                    <th style={{whiteSpace: "nowrap", padding: "20px", border: "1px solid #cccccc6e"}}>IMPLICATIONS OF READINESS SCORE</th>
                                    <th style={{whiteSpace: "nowrap", padding: "20px", border: "1px solid #cccccc6e"}}>RECOMMENDED SOLUTIONS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> POSITIONING </td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.positioning }</td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.positioning > 4.5 ? "The company has goodwill that makes it to be well positioned for succesful export business." : "Low score In positioning is an indication that the product is not well received in the local market." }</td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.positioning > 4.5 ? "Good to go." : "The company needs a good management team to establish the business in the local market." }</td>
                                </tr>
                                <tr>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> PROMOTERS </td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.promoters }</td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.promoters > 4.5 ? "The promoters of the company are committed to export business." : "Low score in promoter is an indication that the business owner is inexperience and unwilling to commit resources to support export." }</td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.promoters > 4.5 ? "Good to go." : "The business owner needs more exposure to export market potentials to stimulate interest in exportation." }</td>
                                </tr>
                                <tr>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> PRODUCTS </td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.products }</td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.products > 4.5 ? "The products labelling, quality and packaging are ready for the export market." : "Low score in product is an indication that the product is not unique, low quality, easy to replicate and poor packaging." }</td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.products > 4.5 ? "Good to go." : "The company needs to upgrage product quality, labelling  and packaging." }</td>
                                </tr>
                                <tr>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> PRICING </td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.pricing }</td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.pricing > 4.5 ? "The pricing of the products are competitive in the export market." : "Low score in pricing is a sign of lack of competitiveness." }</td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.pricing > 4.5 ? "Good to go." : "The company needs to deploy different strategies in order to reduce cost of production and delivery to export market." }</td>
                                </tr>
                                <tr>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> PREDISPOSITION </td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.predisposition }</td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.predisposition > 4.5 ? "The staff of the company have good predisposition to exportation." : "Low score in predisposition is an indication of lack of foreign orientation among staff ." }</td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.predisposition > 4.5 ? "Good to go." : "The company needs to organise programmes to boost foreign orientation of its staff." }</td>
                                </tr>
                                <tr>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> PURPOSE </td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.purpose }</td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.purpose > 4.5 ? "The company has a very strong reason for going into the export business." : "Low score in purpose is an indication that the reasons for doing export business is not strong enough." }</td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.purpose > 4.5 ? "Good to go." : "The company needs to find a strong reason to export through exposure to sell in the export market." }</td>
                                </tr>
                                <tr>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> PAYMENT </td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.payment }</td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.payment > 4.5 ? "The company has an excellent understanding of how to raise funds and mitigate payment risks." : "Low score in payment is an indication that exporter cannot source funds to grow export volume and also unable to mitigate payment risk." }</td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.payment > 4.5 ? "Good to go." : "The company needs to learn alternative source of funding and get trained on how to mitigate payment risk." }</td>
                                </tr>
                                <tr>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> PRODUCTION </td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.production }</td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.production > 4.5 ? "The company has an excellent structure to meet the new demands in the export market." : "Low score in production is an indication of inability to meet a surge in demand from buyers abroad." }</td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.production > 4.5 ? "Good to go." : "The company needs to learn various means of boosting production capacity in order to meet a surge in demand." }</td>
                                </tr>
                                <tr>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> PROFICIENCY </td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.proficiency }</td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.proficiency > 4.5 ? "The company has a very competent team to successfully run the export business." : "Low score in proficiency is an indication of lack of competence in export business management." }</td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.proficiency > 4.5 ? "Good to go." : "The company needs to do capacity building in export business management programme for its staff." }</td>
                                </tr>
                                <tr>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> PEOPLE </td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.people }</td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.people > 4.5 ? "The company has an excellent understanding of the consumers in the export market." : "Low score in people is an indication of lack of understanding of the consumers in the export market." }</td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.people > 4.5 ? "Good to go." : "The company needs to learn about the characteristics of a typical consumer in export market." }</td>
                                </tr>
                                <tr>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> PAPERWORK </td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.paperwork }</td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.paperwork > 4.5 ? "The company has an excellent understanding of documentation involve in the home and export market." : "Low score in paperwork is an indication of lack of understanding of the documentation involve in the local and export market." }</td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.paperwork > 4.5 ? "Good to go." : "The company needs to learn the documentation requirement for its products in both the local and export markets." }</td>
                                </tr>
                                <tr>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> POTENTIALS </td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.potentials }</td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.potentials > 4.5 ? "The company has an excellent understanding of the export market trends and opportunities around the world." : "Low score in potential is an indication of lack of understanding of the export market opportunities." }</td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.potentials > 4.5 ? "Good to go." : "The company needs to do an online research to know the market potential, trends and viable export market." }</td>
                                </tr>
                                <tr>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> PROMOTION </td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.promotion }</td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.promotion > 4.5 ? "The company has an excellent understanding of how to reach the importers and consumers in the export market." : "Low score in promotion is an indication of lack of capacity or understanding of how to reach potential importers and consumers in the export market." }</td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.promotion > 4.5 ? "Good to go." : "The company needs to raise funds to attend trade fair and also learn how to use different platforms to promote product in the export market." }</td>
                                </tr>
                                <tr>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> PURCHASERS </td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.purchasers }</td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.purchasers > 4.5 ? "The company has a good access to purchasers who importers and  distribution channel operators in the export market." : "Low score in purchasers is an indication of lack of understanding of how to identify and access importers and distribution channel operators in the export market." }</td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.purchasers > 4.5 ? "Good to go." : "The company needs to visit the export market or appoint an agents that will facilitate access to importers and distribution channel operators." }</td>
                                </tr>
                                <tr>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> PARTNERSHIP </td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.partnership }</td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.partnership > 4.5 ? "The company has a good access to partners who are export business support service providers in the export market." : "Low score in partnership is an indication of lack of understanding of how to identify and access export business service providers in the export market." }</td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.partnership > 4.5 ? "Good to go." : "The company needs to visit the export market and form an alliance with the providers of different export business services in the export market." }</td>
                                </tr>
                                <tr>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> SCORE </td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.score }</td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}} colSpan="2" rowSpan="2">
                                    {
                                        report?.category === "A" ? "Export Ready" :
                                            report?.category === "B" ? "Almost Ready" :
                                                report?.category === "C" ? "Midway to Ready" :
                                                    report?.category === "D" ? "Just Starting" :
                                                        "Preparing to Start"
                                    }
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> PERCENTAGE </td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.percent }%</td>
                                </tr>
                                <tr>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> READINESS CATEGORY & INTERPRETATION </td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}}> { report?.category }</td>
                                    <td style={{padding: "15px 10px", minWidth: "60px", maxWidth: "400px", border: "1px solid #cccccc6e"}} colSpan="2">
                                    {
                                        report?.category === "A" ? "Positioned for sustainable export business – can start exporting immediately" :
                                            report?.category === "B" ? "Almost positioned for sustainable export business- can start export, but needs to fix few areas readiness to be sustainable" :
                                                report?.category === "C" ? "Midway to positioning for sustainable export business – have a long way to go, should not start export but focus on export readiness" :
                                                    report?.category === "D" ? "Just starting the journey to export business -needs to concentrate local business development and less on export readiness" :
                                                        "Getting ready to start the journey to exportation-  focus on overcoming the teething problem of business setup"
                                    }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            :   
                <div className="spinner">
                    <IconLoading className="loader" />
                </div>
            }
        </div>
    )
}

export default Report
