
export function IconOpenInNew() {
    return (
      <svg width="1em" height="1em" viewBox="0 0 24 24" ><path d="M19 19H5V5h7V3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83l1.41 1.41L19 6.41V10h2V3h-7z" fill="currentColor"></path></svg>
    )
}



export function IconArrowUp(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}><path d="M13 20h-2V8l-5.5 5.5l-1.42-1.42L12 4.16l7.92 7.92l-1.42 1.42L13 8v12z" fill="currentColor"></path></svg>
  )
}


export function IconArrowDown(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}><path d="M11 4h2v12l5.5-5.5l1.42 1.42L12 19.84l-7.92-7.92L5.5 10.5L11 16V4z" fill="currentColor"></path></svg>
  )
}


export function IconBin(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 512" {...props}><rect x="32" y="48" width="448" height="80" rx="32" ry="32" fill="currentColor"></rect><path d="M74.45 160a8 8 0 0 0-8 8.83l26.31 252.56a1.5 1.5 0 0 0 0 .22A48 48 0 0 0 140.45 464h231.09a48 48 0 0 0 47.67-42.39v-.21l26.27-252.57a8 8 0 0 0-8-8.83zm248.86 180.69a16 16 0 1 1-22.63 22.62L256 318.63l-44.69 44.68a16 16 0 0 1-22.63-22.62L233.37 296l-44.69-44.69a16 16 0 0 1 22.63-22.62L256 273.37l44.68-44.68a16 16 0 0 1 22.63 22.62L278.62 296z" fill="currentColor"></path></svg>
  )
}

export function IconLoading() {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" ><path fill="currentColor" fillRule="evenodd" d="M11 16a2 2 0 1 1 0 4a2 2 0 0 1 0-4zm-6.259-3a2.5 2.5 0 1 1 0 5a2.5 2.5 0 0 1 0-5zm11.578.5a2 2 0 1 1 0 4a2 2 0 0 1 0-4zM18.5 9.319a1.5 1.5 0 1 1 0 3a1.5 1.5 0 0 1 0-3zM2.5 6a2.5 2.5 0 1 1 0 5a2.5 2.5 0 0 1 0-5zm15.286-.793a1 1 0 1 1 0 2a1 1 0 0 1 0-2zM8 0a3 3 0 1 1 0 6a3 3 0 0 1 0-6zm7.5 3a.5.5 0 1 1 0 1a.5.5 0 0 1 0-1z"></path></svg>
  )
}

export function IconSearch() {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 32" ><path d="M29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29zM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9z" fill="currentColor"></path></svg>
  )
}
