// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCcL2E5SA7IW4GuJiYk_CHozd2BtGxNo0k",
    authDomain: "csvanalyzer3t.firebaseapp.com",
    projectId: "csvanalyzer3t",
    storageBucket: "csvanalyzer3t.appspot.com",
    messagingSenderId: "921812804264",
    appId: "1:921812804264:web:4b7e76bda3f88d5563989d",
    measurementId: "G-BBVP3ZQN00"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app)

export default db