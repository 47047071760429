import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./css/Records.css";
import { IconArrowDown, IconArrowUp, IconBin, IconLoading, IconOpenInNew} from "./Icons/Icons";
import { collection, getDocs, doc, deleteDoc, query, orderBy } from "firebase/firestore";
import db from "../firebase";

const Records = () => {
  const [profiles, setProfiles] = useState();
    const [loading, setLoading] = useState(true);
    const [searchResult, setSearchResult] = useState()
    const list = searchResult || profiles

    useEffect(() => {
        getDocs(collection(db, "records")).then(docs => {
            let newProfiles = []
            docs.forEach(col => {
                newProfiles.push({...col.data(), id: col.id})
            })
            setProfiles(newProfiles)
            setLoading(false)
        })
    }, []);
    const Search = (term) => {
        if (term) {
          let newContestants = []
          let searchResulttemp = profiles.filter(item => item.email.toLowerCase().includes(term.toLowerCase()) || item.company.toLowerCase().includes(term.toLowerCase()))
            newContestants = [...searchResulttemp]
          setSearchResult(newContestants)
        }
        else{
          setSearchResult(null)
        }
    }
    const Sort = (e) => {
        let newProfile =[]
        getDocs(query(collection(db, "records"), orderBy(e.type, e.dir))).then(docs => {
            docs.forEach(req => {
                newProfile.push({...req.data(), id:req.id});
            })
            setProfiles(newProfile);
        }).catch(err => {})
    }
    const Delete = (e) => {
        let agree = window.confirm(`Confirm to delete ${e.company}.`)
        if(agree){
            deleteDoc(doc(db, 'records', e.id.trim()))
            .then(req => {
                setProfiles(profiles.filter(item => item.id !== e.id))
            })
            .catch(err => {})
        }
    }
  return (
      <div className="records">
            <div className="heading">
                <div className="btns">
                    <Link to="/" className="btn">Home</Link>
                    <Link to="/analyze" className="btn">Import New Records</Link>
                </div>
                <form className="search" onSubmit={(e) => {e.preventDefault()}}>
                    <input type="text" placeholder="Search . . ." onChange={(e) => Search(e.target.value)}/>
                </form>
            </div>
        {!loading ?
            list?.length > 0 ? (
            <>
                <div>
                    <p>{`${list.length} records displayed out of ${profiles.length} records`}</p>
                </div>
                <div className="table_responsive">
                    <table className="table">
                        <thead>
                        <tr>
                            <th>S/N</th>
                            <th>ACTION</th>
                            <th>TIMESTAMP 
                                <button className="filter" onClick={() => Sort({type:'timestamp', dir: 'asc'})}><IconArrowUp /></button>
                                <button className="filter" onClick={() => Sort({type:'timestamp', dir: 'desc'})}><IconArrowDown /></button>
                            </th>
                            <th> EMAIL ADDRESS
                                <button className="filter" onClick={() => Sort({type:'email', dir: 'asc'})}><IconArrowUp /></button>
                                <button className="filter" onClick={() => Sort({type:'email', dir: 'desc'})}><IconArrowDown /></button>
                            </th>
                            <th> COMPANY NAME & REPRESENTATIVE NAME
                                <button className="filter" onClick={() => Sort({type:'company', dir: 'asc'})}><IconArrowUp /></button>
                                <button className="filter" onClick={() => Sort({type:'company', dir: 'desc'})}><IconArrowDown /></button> 
                            </th>
                            <th> COMPANY ADDRESS</th>
                            <th> PRODUCT NAME</th>
                            <th> PHONE NUMBER</th>
                            <th> PROMOTERS</th>
                            <th> PRODUCTS</th>
                            <th> PRICING</th>
                            <th> PREDISPOSITION</th>
                            <th> PURPOSE</th>
                            <th> PAYMENT</th>
                            <th> PRODUCTION</th>
                            <th> POSITIONING</th>
                            <th> PROFICIENCY</th>
                            <th> PEOPLE</th>
                            <th> PAPERWORK</th>
                            <th> POTENTIALS</th>
                            <th> PROMOTION</th>
                            <th> PURCHASERS</th>
                            <th> PARTNERSHIP</th>
                            <th> SCORE</th>
                            <th> PERCENTAGE</th>
                            <th> CATEGORY</th>
                        </tr>
                        </thead>
                        <tbody>
                        {list.map((profile, index) => (
                            <tr key={profile.id}>
                            <td>{index}</td>
                            <td>
                                <div className="actions_list">
                                    <Link to={`/report/${profile.id}`} className="linkIcon">
                                    <IconOpenInNew />
                                    </Link>{" "}
                                    <button className="linkIcon" onClick={()=> Delete(profile)}><IconBin /></button>
                                </div>
                            </td>
                            <td>{new Date(profile.timestamp.seconds*1000).toLocaleString()}</td>
                            <td>{profile.email}</td>
                            <td>{profile.company}</td>
                            <td>{profile.address}</td>
                            <td>{profile.product}</td>
                            <td>{profile.phoneNumber}</td>
                            <td>{profile.promoters}</td>
                            <td>{profile.products}</td>
                            <td>{profile.pricing}</td>
                            <td>{profile.predisposition}</td>
                            <td>{profile.purpose}</td>
                            <td>{profile.payment}</td>
                            <td>{profile.production}</td>
                            <td>{profile.positioning}</td>
                            <td>{profile.proficiency}</td>
                            <td>{profile.people}</td>
                            <td>{profile.paperwork}</td>
                            <td>{profile.potentials}</td>
                            <td>{profile.promotion}</td>
                            <td>{profile.purchasers}</td>
                            <td>{profile.partnership}</td>
                            <td>{profile.score}</td>
                            <td>{profile.percent}</td>
                            <td>{profile.category}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </>
        ) : (
            <h1>No old Record</h1>
              ) :
              <div className="spinner">
                  <IconLoading className="loader" />
              </div>
        }
    </div>
  );
};

export default Records;
